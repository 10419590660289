<template>
  <div class="box">

    <template v-if="info.shopid === 0 || status===3">
      <div class=" content_container" style="min-height:auto" v-if="status===3">
        <el-steps :active="3" finish-status="success">
          <el-step title="提交入驻申请"></el-step>
          <el-step title="入驻申请审核中"></el-step>
          <el-step title="审核结果"></el-step>
        </el-steps>
        <div class="cont">
          <el-result icon="error" title="  很抱歉，您的入驻资料未通过审核。" subTitle=" 请修改后重新提交申请">
          </el-result>
          <p class="reason"> 未通过原因：{{ reason }}</p>
          <br />
        </div>
      </div>
      <div class="content_container">
        <p class="title">仪商城商家入驻申请</p>
        <el-divider content-position="left">基础信息</el-divider>
        <el-form ref="shopRegister" :model="shopRegister" label-width="150px" :rules="rules">
          <el-form-item label="公司全称" prop="company_name">
            <el-input v-model="shopRegister.company_name" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="公司经营介绍" prop="company_info">
            <el-input type="textarea" :rows="2" v-model="shopRegister.company_info" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="办公电话" prop="shop_tel">
            <el-input v-model="shopRegister.shop_tel" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="社会信用代码" prop="shop_lience_num">
            <el-input v-model="shopRegister.shop_lience_num" placeholder="请与营业执照上一致"></el-input>
          </el-form-item>

          <el-form-item label="所在地区" prop="city_id">
            <el-select v-model="shopRegister.province_id" filterable placeholder="请选择" @change="getCity(true)" style='width:25%'>
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>

            <el-select style='width:25%' v-model="shopRegister.city_id" filterable placeholder="请选择" @change="getArea(true)">
              <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>

            <el-select style='width:25%' v-model="shopRegister.area_id" filterable placeholder="请选择" @change="getStreet(true)">
              <el-option v-for="item in options3" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>

            <el-select style='width:25%' v-model="shopRegister.street_id" filterable placeholder="请选择">
              <el-option v-for="item in options4" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="注册地址" prop="shop_address">
            <el-input v-model="shopRegister.shop_address" placeholder="请输入注册地址"></el-input>
          </el-form-item>
          <!-- <el-form-item label="联系地址" prop="shop_contact_address">
            <el-input v-model="shopRegister.shop_contact_address" placeholder="请输入联系地址（收货地址）"></el-input>
          </el-form-item> -->

          <el-divider content-position="left">资料上传</el-divider>

          <el-form-item label="法人身份证正面" prop="shop_card_z">
            <uploader field="img" :multiple="false" v-model="shopRegister.shop_card_z" :file-list="shop_card_z01" exts="png||jpg||gif" />
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="shopRegister.shop_card_z_img" alt="">
            </el-dialog>
            <dd>请上传身份证正面图</dd>
          </el-form-item>
          <el-form-item class="" label="法人身份证反面" prop="shop_card_f">

            <uploader field="img" :multiple="false" v-model="shopRegister.shop_card_f" :file-list="shop_card_f01" exts="png||jpg||gif" />
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="shopRegister.shop_card_f_img" alt="">
            </el-dialog>
            <p>请上传身份证反面图</p>

          </el-form-item>
          <el-form-item label="请上传营业执照复印件（需盖公章）" prop="shop_business_license">

            <uploader :data="{is_watermark:1}" field="img" :multiple="false" :file-list="shop_business_license01" v-model="shopRegister.shop_business_license" exts="png||jpg||gif" />
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" alt="">
            </el-dialog>

          </el-form-item>
          <el-divider content-position="left">店铺信息</el-divider>
          <el-form-item label="店铺名称" prop="shop_name">

            <el-input v-model="shopRegister.shop_name" placeholder="店铺名称"></el-input>

          </el-form-item>

          <el-form-item label="店铺介绍" prop="shop_info">

            <el-input type="textarea" :rows="2" v-model="shopRegister.shop_info" placeholder="店铺介绍"></el-input>

          </el-form-item>

          <el-form-item label="主营品类" prop="shop_scope_id">
            <el-cascader style="width:100%" v-model="shopRegister.shop_scope_id" :options="listDataSort"></el-cascader>
          </el-form-item>
          <el-form-item v-show="show_brand" label="主营品牌" prop="scope_id">

            <el-select v-model="shopRegister.scope_id" style="width:100%" filterable placeholder="请选择主营品牌">
              <el-option :value="item.value" v-for="item in listDataBrand" :key="item.value">
                {{ item.value }}
              </el-option>
            </el-select>
          </el-form-item>

           

          <el-form-item label="经营模式" prop="shop_model">

            <el-select style="width:100%" v-model="shopRegister.shop_model" filterable placeholder="请选择经营模式">
              <el-option :value="item.value" :label="item.label" v-for="item in listData" :key="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="入驻联系人" prop="shop_contact">

            <el-input v-model="shopRegister.shop_contact" placeholder="入驻联系人"></el-input>

          </el-form-item>
          <el-form-item label="入驻人手机" prop="shop_mobile">

            <el-input v-model="shopRegister.shop_mobile" placeholder="入驻人手机"></el-input>

          </el-form-item>
          <el-form-item label="入驻人邮箱" prop="shop_email">

            <el-input v-model="shopRegister.shop_email" placeholder="入驻人邮箱"></el-input>

          </el-form-item>

          <el-divider content-position="left">资质证明</el-divider>

          <el-form-item v-if="shopRegister.shop_model == 1" label="商标注册证明" prop="shop_sbzm">

            <uploader :data="{is_watermark:1}" field="img" :multiple="false" v-model="shopRegister.shop_sbzm" :file-list="shop_sbzm01" exts="png||jpg||gif" />
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="shopRegister.shop_sbzm_img" alt="">
            </el-dialog>

            <p> （自有品牌）（复印件需盖章）</p>
          </el-form-item>

          <el-form-item v-if="shopRegister.shop_model == 2" label="品牌权授书" prop="shop_ppsqs">
            <uploader :data="{is_watermark:1}" field="img" :multiple="false" v-model="shopRegister.shop_ppsqs" :file-list="shop_ppsqs" exts="png||jpg||gif" />
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="shopRegister.shop_ppsqs_img" alt="">
            </el-dialog>

            <p> （代理）（复印件需盖章）</p>
          </el-form-item>

          <el-form-item label="质检报告" prop="shop_ppzj">

            <uploader :data="{is_watermark:1}" field="img" :multiple="false" v-model="shopRegister.shop_ppzj" :file-list="shop_ppzj01" exts="png||jpg||gif" />
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="shopRegister.shop_ppzj_img" alt="">
            </el-dialog>
            <p> （复印件需盖章）</p>
          </el-form-item>
          <!-- <el-form-item class="more">

            <el-divider> 
            <span
                @click="more=!more"
                class="hover"
              >其他证明（医疗资质）<i
                  class="el-icon-arrow-right"
                  v-if='!more'
                ></i>
                <i
                  class="el-icon-arrow-down"
                  v-if='more'
                ></i> </span></el-divider>

          </el-form-item>
          <el-form-item
            v-if='more||shopRegister.shop_ylqx'
            label="医疗器械经营许可证"
            prop="shop_ylqx"
          >

            <uploader
              field="img"
              :multiple="false"
              v-model="shopRegister.shop_ylqx"
              :file-list="shop_ylqx01"
              :data="{is_watermark:1}"
              exts="png||jpg||gif"
            />
            <el-dialog :visible.sync="dialogVisible">
              <img
                width="100%"
                :src="shopRegister.shop_ylqx_img"
                alt=""
              >
            </el-dialog>
            <p>仅经营医疗器械产品需要上传（复印件需盖章）</p>
          </el-form-item>
          <el-form-item
            v-if='more||shopRegister.shop_yaop'
            label="互联网药品交易服务资格证书"
            prop="shop_yaop"
          >

            <uploader
              :data="{is_watermark:1}"
              field="img"
              :multiple="false"
              v-model="shopRegister.shop_yaop"
              :file-list="shop_yaop01"
              exts="png||jpg||gif"
            />
            <el-dialog :visible.sync="dialogVisible">
              <img
                width="100%"
                :src="shopRegister.shop_yaop_img"
                alt=""
              >
            </el-dialog>
            <p>仅经营医疗器械产品需要上传（复印件需盖章）</p>

          </el-form-item> -->

          <el-form-item label=" " prop=" ">
            <el-checkbox v-model="zcrules"></el-checkbox>我已阅读并同意<i @click="article_show=!article_show" class="hover" style="color:red"> 《仪商城店铺入驻协议》</i>
            <el-dialog title="《仪商城店铺入驻协议》" :visible.sync="article_show">
              <div v-html='article'></div>

              <p style="text-align:center">
                <el-button type="" @click="article_show=!article_show">已阅读</el-button>
              </p>
            </el-dialog>
          </el-form-item>
        </el-form>
        <p style="text-align:center">
          <el-button class='sumit' type="primary" @click="submitForm('shopRegister')">提交</el-button>
        </p>
      </div>
    </template>
    <template v-else>
      <div class="box" v-if="status===0">
        <div class="tips">
          <!-- 待审核 -->
          <el-steps :active="2" finish-status="success">
            <el-step title="提交入驻申请"></el-step>
            <el-step title="入驻申请审核中"></el-step>
            <el-step title="审核结果"></el-step>
          </el-steps>
          <div class="cont">
            <el-result icon="primary" title=" 您的入驻资料已提交，正在审核中..." subTitle=" 请耐心等待！">
              <template slot="extra">
                <el-button @click="$router.push({ path: '/' })" type="primary" size="medium">返回首页</el-button>
              </template>
            </el-result>
            <br />
          </div>
        </div>
      </div>
      <div class="box" v-else-if="status===2">
        <div class="tips">
          <!-- 审核通过 未交保证金 -->
          <el-steps :active="3" finish-status="success">
            <el-step title="提交入驻申请"></el-step>
            <el-step title="入驻申请审核中"></el-step>
            <el-step title="审核结果"></el-step>
          </el-steps>

          <div class="cont">
            <el-result icon="success" title=" 您的入驻资料已经通过审核，请在十天内登录商家后台交付保证金即可开店！" subTitle="十天后未缴费保证金将自动取消提交申请">
              <template slot="extra">
                <el-button @click="toMyShop" type="success" size="medium">登录商家后台</el-button>
              </template>
            </el-result>
            <br />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      article_show: false,
      article: "",
      zcrules: false,
      more: false,
      reason: "",
      show_brand: false,
      dialogVisible: false,

      listData: [
        {
          label: "自有品牌",
          value: "1"
        },
        {
          label: "代理",
          value: "2"
        }
      ],
      listDataBrand: [],

      member_type: this.$store.state.member.type,
      company_license: "",
      options: "",
      options2: "",
      options3: "",
      options4: "",
      listDataSort: [],

      shopRegister: {
        //基础信息
        company_name: "", //公司名称
        company_info: "", //公司信息
        shop_tel: "", //公司电话
        shop_lience_num: "", //社会信用码
        shop_address: "", //详细地址
        shop_contact_address: "",
        province_id: "", //所在区域
        city_id: "",
        area_id: "",
        street_id: "",
        province: [],
        city: [],
        country: [],
        town: [],
        // 资料上传
        shop_business_license: "", //营业执照
        shop_business_license_img: undefined,
        shop_card_z: "", //身份证正面
        shop_card_z_img: undefined,
        shop_card_f: "", //身份证反面
        shop_card_f_img: undefined,
        shop_ylqx: "", //医疗器械（2）
        shop_ylqx_img: undefined,
        shop_yaop: "",
        shop_yaop_img: undefined,
        //店铺信息
        shop_name: "", //店铺名称
        shop_info: "", // 店铺介绍
        shop_sort: "",
        shop_scope: "", //主营品类
        shop_scope_id: "", //主营品类ID
        scope_id: "", //主营品牌
        shop_brand_id: "", //主营品牌ID
        shop_model: "", //经营模式
        shop_contact: "", //入驻人联系
        shop_mobile: "", //联系人电话
        shop_email: "", //联系人邮箱
        //资质证明
        shop_sbzm: "", //商标注册证明
        shop_sbzm_img: undefined,
        shop_ppsqs: "", //品牌授权书
        shop_ppsqs_img: undefined,
        shop_ppzj: "", //质检报告
        shop_ppzj_img: undefined
      },
      shop_card_z01: [],
      shop_card_f01: [],
      shop_business_license01: [],
      shop_sbzm01: [],
      shop_ppsqs01: [],
      shop_ppzj01: [],
      shop_ylqx01: [],
      shop_yaop01: [],
      rules: {
        company_name: [
          {
            required: true,
            message: "请填写公司名称",
          }
        ],
        company_info: [
          {
            required: true,
            message: "请填写公司介绍信息",
          }
        ],
        shop_tel: [
          {
            required: true,
            message: "请填写办公电话",
          },

          {
            pattern: this.$validate.mobile,
            message: "请输入正确的电话",
          }
        ],
        // shop_bank_name: [
        //   {
        //     required: true,
        //     message: "请填写银行名称",
        //     trigger: "blur"
        //   }
        // ],

        // shop_bank: [
        //   {
        //     required: true,
        //     message: "请填写银行账号",
        //     trigger: "blur"
        //   }
        // ],
        shop_lience_num: [
          {
            required: true,
            message: "请填写社会信息码",
          }
        ],
        shop_address: [
          {
            required: true,
            message: "请填写公司注册地址",
          }
        ],
        // shop_contact_address: [
        //   {
        //     required: true,
        //     message: "请填写公司联系地址（收货地址）",
        //     trigger: "blur"
        //   }
        // ],
        city_id: [
          {
            required: true,
            message: "请填写所在区域",
          }
        ],

        shop_card_z: [
          {
            required: true,
            message: "请上传身份证正面",
          }
        ],
        shop_card_f: [
          {
            required: true,
            message: "请上传身份证反面",
          }
        ],
        shop_business_license: [
          {
            required: true,
            message: "请上传营业执照",
          }
        ],
        shop_name: [
          {
            required: true,
            message: "请填写公司名称",
          }
        ],
        shop_info: [
          {
            required: true,
            message: "请填写店铺介绍",
          }
        ],

        shop_scope_id: [
          {
            required: true,
            message: "请填写主营品类",
          }
        ],
        scope_id: [
          {
            required: true,
            message: "请填写主营品牌"
          }
        ],
        shop_model: [
          {
            required: true,
            message: "请填写经营模式",
          }
        ],
        shop_contact: [
          {
            required: true,
            message: "请填写入驻联系人",
          }
        ],
        shop_mobile: [
          {
            required: true,
            message: "请填写联系人电话",
          },
          {
            pattern: this.$validate.phone,
            message: "请输入正确的手机号",
          }
        ],
        shop_email: [
          {
            required: true,
            message: "请填写联系人邮箱",
          },
          {
            pattern: this.$validate.email,
            message: "请输入正确的邮箱",
          }
        ],
        shop_sbzm: [
          {
            required: true,
            message: "请上传商标注册证明",
          }
        ],
        shop_ppsqs: [
          {
            required: true,
            message: "请上传品牌授权书",
          }
        ],
        shop_ppzj: [
          {
            required: true,
            message: "请上传质检报告",
          }
        ]
      },
      info: { shopId: "" },
      shop_bond_status: 1,
      status: undefined
    };
  },

  watch: {
    "shopRegister.shop_scope_id"() {
      this.show_brand = true;
      let id = this.shopRegister.shop_scope_id[1];
       this.shopRegister.scope_id=''
      this.getBrand(id);
      let a = "";
      let b = "";

      this.listDataSort.forEach(o => {
        if (o.value == this.shopRegister.shop_scope_id[0]) {
          a = o.label; //赛选1级品类

          o.children.forEach(e => {
            if (e.value == this.shopRegister.shop_scope_id[1]) {
              b = e.label; //赛选2级品类
            }
          });
        }
      });

      this.shopRegister.shop_scope = a + "/" + b;

      console.log("arr", this.shopRegister.shop_scope);
    }
  },
  computed: {},
  mounted() {
    this.getInfo();

    this.getProvince();
    this.getCategory();
    this.getArticle();
  },
  methods: {
    info2() {
      this.$post("home/user/info", { member_id: this.$store.state.member.id }).then(res => {
        console.log("res1111", res);
        this.info.shopId = res.shopid;

        this.$get("seller/shop/" + this.info.shopId).then(res => {
          console.log("shop", res);
          this.member_id = res.$store.state.member.id; //必填
          this.shopRegister.shop_address = res.shop_address; //必填
          // this.shopRegister.shop_contact_address = res.shop_contact_address;
          this.shopRegister.province_id = res.shop_province; //必填 省
          this.shopRegister.city_id = res.shop_city; //必填 市
          this.shopRegister.area_id = res.shop_area; //必填 区
          this.shopRegister.street_id = res.shop_street; //必填 街道
          this.shopRegister.shop_info = res.shop_info; //必填店铺信息
          this.shopRegister.shop_logo = res.shop_logo; //必填
          this.shopRegister.shop_name = res.shop_name; //必填店铺名称
          this.shopRegister.shop_scope = res.shop_scope; //必填    主营品类
          this.shopRegister.shop_scope_id = res.shop_scope_id
            .split(",")
            .map(a => parseInt(a)); //必填    主营品类ID
          this.shopRegister.scope_id = res.scope_id; //主营品牌
          this.shopRegister.shop_brand_id = res.shop_brand_id; //主营品牌ID
          this.shopRegister.shop_model = res.shop_model; //经营模式
          this.shopRegister.shop_contact = res.shop_contact; //入驻人联系
          this.shopRegister.shop_mobile = res.shop_mobile; //联系人电话
          this.shopRegister.shop_email = res.shop_email; //联系人邮箱
          this.shopRegister.company_name = res.company_name; //公司名称
          this.shopRegister.company_info = res.company_info; //公司信息
          this.shopRegister.shop_tel = res.shop_tel; //公司电话
          // this.shopRegister.shop_bank_name = res.shop_bank_name; //银行名称
          // this.shopRegister.shop_bank = res.shop_bank; //银行账号
          this.shopRegister.shop_lience_num = res.shop_lience_num; //社会信用码
          this.shopRegister.shop_business_license = res.shop_business_license; //营业执照
          this.shopRegister.shop_card_z = res.shop_card_z; //身份证正面
          this.shopRegister.shop_card_f = res.shop_card_f; //身份证反面
          this.shopRegister.shop_yaop = res.shop_yaop; //医疗器械许可
          this.shopRegister.shop_ylqx = res.shop_ylqx; //医疗器械许可
          this.shopRegister.shop_sbzm = res.shop_sbzm; //商标注册证明
          this.shopRegister.shop_ppsqs = res.shop_ppsqs; //品牌授权书
          this.shopRegister.shop_ppzj = res.shop_ppzj; //质检报告
          this.shopRegister.region =
            res.province + res.city + (res.area || "") + (res.street || "");
          this.shopRegister.shop_card_z_img =
            this.$config.baseUrl + res.shopcardz.files_path;
          this.shop_card_z01 = [
            {
              id: this.shopRegister.shop_card_z,
              url: this.shopRegister.shop_card_z_img
            }
          ];
          this.shopRegister.shop_card_f_img =
            this.$config.baseUrl + res.shopcardf.files_path;
          this.shop_card_f01 = [
            {
              id: this.shopRegister.shop_card_f,
              url: this.shopRegister.shop_card_f_img
            }
          ];
          this.shopRegister.shop_business_license_img =
            this.$config.baseUrl + res.shopbusinesslicense.files_path;
          this.shop_business_license01 = [
            {
              id: this.shopRegister.shop_business_license,
              url: this.shopRegister.shop_business_license_img
            }
          ];

          this.shopRegister.shop_sbzm_img = res.shopsbzm
            ? this.$config.baseUrl + res.shopsbzm.files_path
            : null;
          this.shop_sbzm01 = [
            {
              id: this.shopRegister.shop_sbzm,
              url: this.shopRegister.shop_sbzm_img
            }
          ];
          this.shopRegister.shop_ppsqs_img = res.shopppsqs
            ? this.$config.baseUrl + res.shopppsqs.files_path
            : null;
          this.shop_ppsqs = [
            {
              id: this.shopRegister.shop_ppsqs,
              url: this.shopRegister.shop_ppsqs_img
            }
          ];
          this.shopRegister.shop_ppzj_img = res.shopppzj
            ? this.$config.baseUrl + res.shopppzj.files_path
            : null;
          this.shop_ppzj01 = [
            {
              id: this.shopRegister.shop_ppzj,
              url: this.shopRegister.shop_ppzj_img
            }
          ];
          this.shopRegister.shop_ylqx_img = res.shopylqx
            ? this.$config.baseUrl + res.shopylqx.files_path
            : null;
          this.shop_ylqx01 = [
            {
              id: this.shopRegister.shop_ylqx,
              url: this.shopRegister.shop_ylqx_img
            }
          ];
          this.shopRegister.shop_yaop_img = res.shopyaop
            ? this.$config.baseUrl + res.shopyaop.files_path
            : null;
          this.shop_yaop01 = [
            {
              id: this.shopRegister.shop_yaop,
              url: this.shopRegister.shop_yaop_img
            }
          ];

          if (this.shopRegister.shop_scope_id) {
            this.getBrand(this.shopRegister.shop_scope_id[1]);
            this.show_brand = true;
          }

          this.getCity();
          this.getArea();
          this.getStreet();

          console.log(
            " this.shopRegister.shop_card_z ",
            this.shopRegister.shop_card_z
          );
        });
      });
    },

    toMyShop() {
      window.open("http://shop.yishangm.com/dist/index.html", "_blank");
    },

    getArticle() {
      this.$get("/home/article/25").then(res => {
        this.article = res.article_content_wap;
      });
    },
    submitForm(formName) {
      this.listDataBrand.forEach(o => {
        if (o.value == this.shopRegister.scope_id) {
          this.shopRegister.shop_brand_id = o.lable;
        }
      });

      this.$refs[formName].validate((valid, tips) => {

        if (valid) {
          if (!this.zcrules) {
            this.$message.warning("请先阅读注册协议");
            return;
          }
          let data = {
            member_id: this.$store.state.member.id, //必填
            shop_address: this.shopRegister.shop_address, //必填
            shop_province: this.shopRegister.province_id, //必填 省
            shop_city: this.shopRegister.city_id, //必填 市
            shop_area: this.shopRegister.area_id, //必填 区
            shop_street: this.shopRegister.street_id, //必填 街道
            region: this.shopRegister.region,
            shop_info: this.shopRegister.shop_info, //必填店铺信息
            // shop_logo: this.shop_logo, //必填
            shop_name: this.shopRegister.shop_name, //必填店铺名称
            shop_scope: this.shopRegister.shop_scope, //必填    主营品类
            shop_scope_id: this.shopRegister.shop_scope_id.toString(), //必填    主营品类ID
            scope_id: this.shopRegister.scope_id, //主营品牌
            shop_brand_id: this.shopRegister.shop_brand_id, //主营品牌ID
            shop_model: this.shopRegister.shop_model, //经营模式
            shop_contact: this.shopRegister.shop_contact, //入驻人联系
            shop_mobile: this.shopRegister.shop_mobile, //联系人电话
            shop_email: this.shopRegister.shop_email, //联系人邮箱
            company_name: this.shopRegister.company_name, //公司名称
            company_info: this.shopRegister.company_info, //公司信息
            shop_tel: this.shopRegister.shop_tel, //公司电话
            // shop_bank_name: this.shopRegister.shop_bank_name, //银行名称
            // shop_bank: this.shopRegister.shop_bank, //银行账号
            shop_lience_num: this.shopRegister.shop_lience_num, //社会信用码
            shop_business_license: this.shopRegister.shop_business_license, //营业执照
            // shop_contact_address: this.shopRegister.shop_contact_address, //收货地址

            shop_card_z: this.shopRegister.shop_card_z, //身份证正面
            shop_card_f: this.shopRegister.shop_card_f, //身份证反面
            shop_yaop: this.shopRegister.shop_yaop, //医疗器械许可
            shop_ylqx: this.shopRegister.shop_ylqx, //医疗器械许可
            shop_sbzm: this.shopRegister.shop_sbzm, //商标注册证明
            shop_ppsqs: this.shopRegister.shop_ppsqs, //品牌授权书
            shop_ppzj: this.shopRegister.shop_ppzj, //质检报告
            status: 0
          };
           if (this.info.shopId) {
            this.$put("admin/shop/" + this.info.shopId, data).then(res => {
              this.$message.success("提交成功！");
              this.getInfo();
            });
          } else {
            this.$post("seller/shop", data).then(res => {
              this.$message.success("提交成功！");
              this.getInfo();
            });
          }
        } else {
          for (let key in tips) {
            return this.$message.warning(tips[key][0].message);
          }
          let first = Object.keys(tips)[0];
          console.log('tips', tips);
          return false;
        }
      });
    },
    //获取会员信息
    getInfo() {
      this.$post("home/user/info", { member_id: this.$store.state.member.id }).then(res => {
        this.info = res;
        if (!res.shopid) return;
        this.$get("seller/shop/" + res.shopid).then(res => {
          this.status = res.status;
          this.shop_bond_status = res.shop_bond_status;
          this.reason = res.shop_reason;
          this.shopRegister.company_name = res.company_name;

          if (this.status == 3) {
            this.info2();
          }
        });
      });
    },
    //获取分类联动品牌

    getBrand(id) {
      this.$post("admin/goodsCate/brand", { id: id, status: 1 }).then(res => {
        this.listDataBrand = res.map(item => {
          return {
            lable: item.id,
            value: item.name
          };
        });
        // this.listDataBrand.push({
        //   label: 0,
        //   value: "其他"
        // });

        console.log("listDataBrand", this.listDataBrand);
      });
    },
    getCategory() {
      //获取品类 listDataSort
      this.$get("home/goodsCategory").then(res => {
        res.forEach(a => {
          this.listDataSort.push({
            value: a.id,
            label: a.cate_name,
            children: a.cc.map(b => {
              return { value: b.id, label: b.cate_name };
            })
          });
        });
      });
    },

    getProvince() {
      this.$post("home/regions/index", {
        level: 1,
        pid: 0
      }).then(res => {
        this.options = res;
      });
    },
    getCity(isChange) {
      if (isChange) {
        this.shopRegister.city_id = undefined;
        this.shopRegister.area_id = undefined;
        this.shopRegister.street_id = undefined;
        this.options2 = [];
        this.options3 = [];
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 2,
        pid: this.shopRegister.province_id
      }).then(res => {
        this.options2 = res;
      });
    },
    getArea(isChange) {
      if (isChange) {
        this.shopRegister.area_id = undefined;
        this.shopRegister.street_id = undefined;
        this.options3 = [];
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 3,
        pid: this.shopRegister.city_id
      }).then(res => {
        this.options3 = res;
      });
    },
    getStreet(isChange) {
      if (isChange) {
        this.shopRegister.street_id = undefined;
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 4,
        pid: this.shopRegister.area_id
      }).then(res => {
        this.options4 = res;
      });
    }
  },
  components: {},
  created() { }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.more {
  text-align: center;
  i {
    font-size: 14px;
  }
}
.reason {
  color: red;
  text-align: left;
  width: 100%;
  padding: 20px;
  background: #eee;
}
.tips {
  width: 60%;
  margin: 0 auto;
  padding-top: 50px;
  .cont {
    margin-top: 30px;
    text-align: center;
    background: #fff;
    padding: 20px;
  }
}
.title {
  text-align: center;
  font-size: 18px;
  line-height: 60px;
  font-weight: bold;
}
.sumit {
  // padding:15px 20px;margin-top:15px;border-radius: 3px;
  width: 150px;
  margin: 0 auto;
}
.el-divider__text {
  color: #fe6925;
}
.box {
  min-height: 600px;
  background: #eee;
}
.content_container {
  padding: 20px;
  width: 40% !important;
  margin: 0 auto;

  box-sizing: border-box;
  margin: 0 auto;
  background: #fff;
}
</style>
